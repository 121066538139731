import React from "react";
import PropTypes from "prop-types";
import Image from "../../elements/Image";
import { Link } from "gatsby";

const propTypes = {
	image: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	readingTime: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	layout: PropTypes.string,
};

const defaultProps = {
	image: "",
	date: "",
	title: "",
	link: "",
	layout: "large",
};

const BlogPost = ({ ...props }) => {
	return (
		<div className={`blog-box layout-${props.layout}`}>
			<Link to={props.link}>
				<div className="blog-box__meta">
					{props.date} • {props.readingTime} min read
				</div>
				<h2>{props.title}</h2>
				<div className="blog-box__image">
					<Image src={props.image} alt="Blog" className="img-fluid" />
				</div>
			</Link>
		</div>
	);
};

BlogPost.propTypes = propTypes;
BlogPost.defaultProps = defaultProps;

export default BlogPost;
