import React from "react";
import BlogPost from "./partials/BlogPost";
import BlogHeader from "./partials/BlogHeader";

const BlogSection = ({ posts: { edges: posts } }) => {
	return (
		<section className="section-blog">
			<div className="container">
				<div className="section-blog__wrapper">
					<BlogHeader />
					<div className="section-blog__wrapper__content">
						{posts.map(({ node: post }) => {
							return <BlogPost key={post.fields.slug} image={post.frontmatter.image} date={post.frontmatter.date} readingTime={post.timeToRead} title={post.frontmatter.title} link={post.fields.slug} />;
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogSection;
