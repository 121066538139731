import React from "react";
import { graphql } from "gatsby";

// Sections
import BlogSection from "../../components/sections/BlogSection";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts";

const Blog = ({ data }) => {
	return (
		<Layout>
			<BlogSection posts={data.posts} />
			<Cta />
		</Layout>
	);
};

export default Blog;

export const query = graphql`
  query BlogPageQuery {
    site {
      ...ConfigSiteTitle
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          regex: "/\\/content\\/blog-posts\\//"
        }
      }
    ) {
      group(field: frontmatter___categories) {
        fieldValue
      }
    }
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
         regex: "/\\/content\\/blog-posts\\//"
        }
      }
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        ...BlogPostExcerpt
      }
    }
  }
`;
