import React from "react";

const BlogHeader = () => {
	return (
		<div className="section-blog__wrapper__header">
			<h1 className="h1">The Blog</h1>
			<p>Our latest insights, opinions and general musings as we float about on the vast ocean of tech we call home.</p>
		</div>
	);
};

export default BlogHeader;
